import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <Seo title="Georg Who?" keywords={[`georg gilstein`, `cinematographer`, 'filmemacher', 'kameramann', 'darmstadt', `frankfurt`, `cameraman`, `dop`, `director of photography`, 'film', 'movies','social']} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <div className="kg-width-wide-split">
          <GatsbyImage
              image={data.profile.childImageSharp.gatsbyImageData}
              className="kg-image"
              style={{borderRadius:'10px'}}
            />
          <h2 id="about-hello">
          hi.<br />
          gude.<br />
          привет.<br />
          hola.<br />
          🖐️.<br /><br />
          I'm Georg.
          </h2>

          </div>
          <p>
          I'm a DoP with focus on commercial and artistical films.<br /><br />
          I both work in teams and together with production companies and agencies, but also with direct clients and offer full-service video production.<br /><br />

          My goal is to use my skills and ressources to create informational and emotional content which moves people and make people think.<br /><br />
          
          I'm always open for new projects.<br /><a href="mailto:georg@gilstein.de">I'm stoked to hear from you</a>.<br /><br />

          If I'm not running around with a camera, I'm building <a href="https://heinrig.tech/">sustainable film tech</a>, <a href="https://ridestall.cc/">organize a community-focussed cycling club</a>,<br /> or just try to make the world a little happier.
          
          
          <br/><br/>
          And yes, I have a ton of tech, <br/>
          yes, I have a bachelors degree in Motion Pictures, <br/>
          and yes, I'm a connoisseur of cute doggos.
          <br/><br/>
          All the best,
          <br/>
          Georg</p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    profile: file(
      relativePath: { eq: "profile-pic.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1360)
      }
    }
  }
`

const about = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
export default about;